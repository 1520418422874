import { useState, useEffect, useRef, useCallback, lazy, Suspense } from "react";
import { Form, Input, Select, Button, message, Spin, Space } from "antd";
import { getRedirectUrl, submitLeadWithX } from "../api/lead";
import LeadForm from "../component/LeadForm";
// import FingerprintJS from 'fingerprintjs2';
import { generateUID, getBrowserFingerprint, isMobile } from "../utils/common";
import { useLocation } from "react-router-dom";
import { useForm } from "antd/es/form/Form";

interface PageProps {
  from?: string; // 如果不传，默认为path name
  category?: string; // 默认 lead
  mode?: "deafult" | "audit" | "redirect"; // 默认为default，根据 checkRedirect 判断是否跳转，audit肯定不跳转，redirect肯定跳转
  homeImgs?: string[]; // 主页图片
}

const AgentPage: React.FC<PageProps> = ({ from = "", category = "agent", mode = "default", homeImgs = [] }) => {
  const location = useLocation();
  const [form] = Form.useForm();

  const [redirectToZFB, setRedirectToZFB] = useState(true);
  const [showLeadForm, setShowLeadForm] = useState(false);

  const [loading, setLoading] = useState(false);
  const [buttonText, setButtonText] = useState("点击领取");

  const getFrom = () => {
    if (from) return from;
    const pathname = location.pathname;
    const lastSegment = pathname.substring(pathname.lastIndexOf("/") + 1);
    return lastSegment;
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://www.kuainw.cn/api/open/common/mini/send-sms", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phone: form.getFieldValue("phone"),
          tradeNo: "111",
          merchantOrderNo: "2222",
          signName: "艾子科技",
          templateCode: "SMS_480800165",
          paramMap: {
            code: "UxHt",
          },
        }),
      });

      if (response.ok) {
        setButtonText("已发送，请及时查收");
        message.info("已发送，请及时查收");
      } else {
        setButtonText("请求异常");
        message.error("请求异常");
      }
    } catch (error) {
      setButtonText("请求异常");
      message.error("请求异常");
    } finally {
      setLoading(false);
    }
  };

  return (
    // 表单页的格式不同
    <div className={`landing-container w-full flex flex-col items-center h-screen`}>
      <div className="flex flex-col items-center w-full max-w-md mx-auto pb-16">
        {homeImgs && homeImgs.map((imgSrc, index) => <img key={index} src={imgSrc} alt="AI智能体" className="w-full" />)}
      </div>

      {/* 表单 */}
      <div className="fixed bottom-0 w-full max-w-md mx-auto p-4 bg-gray-300 bg-opacity-50 backdrop-filter backdrop-blur-lg rounded-t-lg pt-8">
        <Form layout="vertical" form={form}>
          <Form.Item
            name="phone"
            rules={[
              { required: true, message: "请输入11位有效的手机号" },
              { pattern: /^1[0-9]{10}$/, message: "请输入11位有效的手机号" },
            ]}
          >
            <Input
              placeholder="请输入手机号，工作人员将稍后联系您"
              className="w-full leading-8 text-lg py-2"
              style={{ textAlign: "center", color: "#000000" }}
            />
          </Form.Item>
          <Form.Item className="px-12 mt-8">
            <Button loading={loading} type="primary" htmlType="submit" className="w-full leading-8 text-lg py-6" onClick={handleSubmit}>
              {buttonText}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default AgentPage;
