import { useState, useEffect, useRef, useCallback, lazy, Suspense } from "react";
import { Form, Input, Select, Button, message, Spin, Space } from "antd";
import { getRedirectUrl, submitLeadWithX } from "../api/lead";
import LeadForm from "../component/LeadForm";
// import FingerprintJS from 'fingerprintjs2';
import { generateUID, getBrowserFingerprint, isMobile } from "../utils/common";
import { useLocation } from "react-router-dom";
import { useForm } from "antd/es/form/Form";

interface PageProps {
  from?: string; // 如果不传，默认为path name
  category?: string; // 默认 lead
  mode?: "deafult" | "audit" | "redirect"; // 默认为default，根据 checkRedirect 判断是否跳转，audit肯定不跳转，redirect肯定跳转
}

const KuaKePage: React.FC<PageProps> = ({ from = "", category = "kuake", mode = "default" }) => {
  const location = useLocation();
  const [form] = Form.useForm();

  const [redirectToZFB, setRedirectToZFB] = useState(true);
  const [showLeadForm, setShowLeadForm] = useState(false);

  const [loading, setLoading] = useState(false);
  const [buttonText, setButtonText] = useState("立即免费领取");

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://www.kuainw.cn/api/open/common/mini/send-sms", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phone: form.getFieldValue("phone"),
          tradeNo: "111",
          merchantOrderNo: "2222",
        }),
      });

      if (response.ok) {
        setButtonText("已发送，请及时查收");
      } else {
        message.error("请求异常");
      }
    } catch (error) {
      message.error("请求异常");
    } finally {
      setLoading(false);
    }
  };

  return (
    // 表单页的格式不同
    <div className={`landing-container w-full flex justify-center h-screen ${redirectToZFB && "items-center"}}`}>
      <Space direction="vertical" className={"block w-full max-w-md relative"}>
        <div className="w-full max-w-md mx-auto pb-32">
          <img src={"https://image.si001.com/touliu/1.jpg"} alt="夸克网盘" className="w-full" />
          <img src={"https://image.si001.com/touliu/2.jpg"} alt="夸克网盘" className="w-full" />
          <img src={"https://image.si001.com/touliu/3.jpg"} alt="夸克网盘" className="w-full" />
          <img src={"https://image.si001.com/touliu/4.jpg"} alt="夸克网盘" className="w-full" />
        </div>

        <div className="fixed bottom-0 w-full max-w-md mx-auto p-4 bg-gray-300 bg-opacity-50 backdrop-filter backdrop-blur-lg">
          <Form layout="vertical" form={form}>
            <Form.Item
              name="phone"
              rules={[
                { required: true, message: "请输入您的手机号" },
                { pattern: /^1[0-9]{10}$/, message: "请输入有效的手机号" },
              ]}
            >
              <Input placeholder="请输入手机号，课程将通过短信发给您" className="w-full" style={{ textAlign: "center", color: "#000000" }} />
            </Form.Item>
            <Form.Item className="px-8">
              <Button type="primary" htmlType="submit" className="w-full" onClick={handleSubmit}>
                立即免费领取
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Space>
    </div>
  );
};

export default KuaKePage;
